<template>
    <n-modal :show="props.show" size="small" @mask-click="onMaskClick">
        <n-card
            style="width: 600px"
            class="event-items-card"
            :title="`成本事件：${dayjs(props.selectedDate * 1000).format(
                'YYYY-MM-DD'
            )}`">
            <div v-for="item in selectedEvents" class="event-item">
                <n-grid cols="8" item-responsive>
                    <n-grid-item class="title" span="6">{{
                        item.title
                    }}</n-grid-item>
                    <n-grid-item class="creator" span="2">
                        创建人：{{ item.creator }}</n-grid-item
                    >
                </n-grid>
                <div v-if="item.info.detail !== ''" class="detail">
                    <n-button
                        size="tiny"
                        type="primary"
                        quaternary
                        @click="openDetail(item.info.detail)"
                        >查看详情</n-button
                    >
                </div>
            </div>
        </n-card>
    </n-modal>
</template>

<style lang="less" scoped>
@import '../../../../common/common.less';

.event-items-card {
    background-color: @bg-color;
    .event-item {
        background-color: #fff;
        .standard-border;
        .standard-shadow;

        padding: 10px;
        margin-bottom: 10px;

        .creator {
            font-size: 12px;
            color: #999;
            text-align: right;
            padding: 0 8px;
        }

        .detail {
            text-align: right;
        }
    }
}
</style>

<script setup>
import { ref, computed } from 'vue';
import { NModal, NCard, NGrid, NGridItem, NButton } from 'naive-ui';
import dayjs from 'dayjs';

const props = defineProps({
    show: {
        type: Boolean,
        default: false,
    },
    relatedEvents: {
        type: Array,
    },
    selectedDate: {
        type: Number,
    },
});

const events = defineEmits(['update:show']);

function onMaskClick() {
    events('update:show', false);
}

let selectedEvents = computed(() =>
    props.relatedEvents.filter(item => item.date === props.selectedDate)
);

function openDetail(url) {
    window.open(url, '_blank');
}
</script>
